<template>
  <div>
    <h2 class="text-xl font-semibold text-gray-900">
     Lijst
    </h2>

    <div class="py-4">
      <div class="no-data" v-if="!items?.length">
        Geen vouchers gevonden
      </div>

      <VouchersTable :items="items" />
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import VouchersTable from '@/pages/administratie/vouchers/Table.vue'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

import usePaginatedApi from '@/hooks/usePaginatedApi'

const { limit, offset, count, items } = usePaginatedApi('VOUCHERS_LIST')

</script>
